<template>
    <div class="max_div" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">

        <el-form label-position="right" label-width="116px" ref='ruleForm'  inline  :model="rowData" :rules="rules">

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="活动名称:"  prop="title">
                    <el-input maxlength="15" :size="tabsize" style="width:300px" v-model="rowData.title" placeholder="请输入标题15个字" ></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="发布公司:" prop="fbname" >
                    <el-input maxlength="15" :size="tabsize" style="width:300px" v-model="rowData.fbname"  placeholder="输入发布公司"></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="发布时间:" prop="fbtime">
                    <el-date-picker :size="tabsize"
                        v-model="rowData.fbtime"
                        type="date"
                        :editable="false"
                        placeholder="发布时间">
                        </el-date-picker>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="城市:" prop="add" >
                    <el-cascader :size="tabsize"
                        v-model="rowData.add"
                        :options="regionData"
                        :filterable="true"
                        @change="handleChange"></el-cascader>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="活动时间:"  prop="value1">

                        <el-date-picker
                            :size="tabsize"
                            v-model="value11"
                            :editable="false"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="截止时间"
                            @change='dateChange'>
                        </el-date-picker>
                        <!-- <el-date-picker
                            v-model="value11"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change='dateChange'>
                        </el-date-picker> -->
                </el-form-item>
            </el-col>

            <!-- <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="条件:"  >
                    <el-checkbox v-model="isRedio">满足两次完整的眼轴数据，且两次眼轴相隔超过25天</el-checkbox>
                </el-form-item>
            </el-col> -->

            <el-col :span="24">
                    <el-form-item label="添加封面图:" prop="imgpath">
                            <el-upload
                            :size="tabsize"
                                class="upload-demo"
                                action="#" 
                                :http-request="uploadImgs"
                                :on-success="imgUpload" 
                                multiple
                                style="width:100px;height:32px;display:flex"
                                :show-file-list="false"
                                ref ="upload1"
                                >
                            <el-button :size="tabsize"  type="primary" @click="fileDelete">点击上传</el-button>
                            </el-upload>
                    </el-form-item>
                    
                    <el-form-item label="" prop="qrcode">
                        <template #label>
                            <div class="test">
                                图片宽高比：2.35:1 , 图片最大不超过1M
                            </div>
                        </template>
                    </el-form-item>
                    <br/>
                <div>
                    <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="vif" :src="rowData.imgpath" :fit="fit"></el-image>
                </div>
            </el-col>

            <el-col :span="24" style="margin-bottom: 10px;"> 
                    <el-form-item label="添加详情图:"  prop="imgs">
                            <el-upload
                            :size="tabsize"
                                class="upload-demo"
                                action="#" 
                                :http-request="uploadImgs"
                                :on-success="imgUpload1"
                                multiple
                                style="width:100px;height:32px;display:flex"
                                ref ="upload1"
                                :show-file-list="false"
                                >
                                <diV>
                                    <el-button :size="tabsize"  type="primary" @click="fileDelete">点击上传</el-button>
                                    <!-- &nbsp;&nbsp;&nbsp;
                                    <h3 style="color:red;white-space:nowrap" v-if="vif1">上传图片小于1M</h3>         -->

                                </diV>
                            </el-upload>
                    </el-form-item>

                    <el-form-item label="" prop="qrcode">
                        <template #label>
                            <div class="test">
                                图片最大不超过1M
                            </div>
                        </template>
                    </el-form-item>

                <div style="margin-top:.3vw;display: flex;flex-wrap: wrap; position: relative;">
                    <div  v-for="(item,index) in imgs" :key="index" style="display:flex;margin-left:10px; margin-top: 10px;">
                        <div class="imgs_button">
                            <el-button size="mini" :disabled="rowData.status == 20 || rowData.status == 30" @click="deleteImg(index)" type="danger">删除</el-button>
                        </div>
                        <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="vifImgs" :src="item" :fit="fit"></el-image>
                    </div>
                </div>

                <!-- <div v-for="(item,index) in imgs" :key="index">
                    <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="vifImgs" :src="item" :fit="fit"></el-image>
                </div> -->
            </el-col>

            <p class="btns">
                <el-button v-if="formShow1" :size="tabsize" type="primary" :disabled="rowData.status == 20" @click="onSubmit('ruleForm')">发布</el-button>
                <el-button v-if="formShow2" :size="tabsize" type="primary" :disabled="rowData.status == 20 || rowData.status == 30" @click="onSubmit('ruleForm')">保存</el-button>
                <el-button v-if="formShow2" :size="tabsize" type="primary" :disabled="rowData.status == 20"   @click="partyReturn(50)">发布</el-button>
                <el-button v-if="formShow2" :size="tabsize" type="primary" :disabled="rowData.status == 40" @click="partyReturn(40)">下架</el-button>
            </p>

            </el-form>
    </div>
</template>

<script>
    import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
    import { uploadOSS } from "../../../requset/apis/alioss";

    export default {
        
        mounted(){
            this.partyId = this.$route.query.id
            if(this.partyId != undefined){
                this.getRowData()
                this.formShow1 = false
                this.formShow2 = true
            }
            this.regionData = regionData
        },
        data(){
            return {
                value11:'',
                loading:false,
                partyId:'',
                rowData:{value1:[]},
                rules:{
                    title: [{
                        required: true,
                        message: '请输入活动名称',
                        trigger: 'blur'
                    }],
                    fbname: [{
                        required: true,
                        message: '请输入发布公司',
                        trigger: 'blur'
                    }],  
                    fbtime: [{
                        required: true,
                        message: '请选择发布时间',
                        trigger: 'blur'
                    }],
                    value11: [{
                        required: true,
                        message: '请选择开始与结束时间',
                        trigger: 'blur'
                    }],  
                    add: [{
                        required: true,
                        message: '请选择活动地址',
                        trigger: 'blur'
                    }],
                    imgpath: [{
                        required: true,
                        message: '请选择封面图片',
                        trigger: 'blur'
                    }],
                    imgs: [{
                        required: true,
                        message: '请选择详情图片',
                        trigger: 'blur'
                    }],
                    
                },
                value1:'',
                vif:true,
                fit:'fit',
                formShow1:true,
                isRedio:false,
                value:'',
                regionData:[],
                upImageName:'',
                vif:false,
                vifImgs:false,
                imgs:[],
                formShow2:false
            }   
        },
        methods:{
            handleChange(value1){
                console.log(value1);
                let a = ''
                let b =''
                let value = []
                value1.forEach((element,index)=>{
                    a+=CodeToText[element]+','
                    b+=element+','
                })
                value = a.split(',')
                this.rowData.addcode = b.slice(0,b.length-1)
                console.log(value);
                this.rowData.add = value1//表单验证
                this.rowData.add1 = value[0]
                this.rowData.add2 = value[1]
                this.rowData.add3 = value[2]
                console.log(this.rowData);
                delete this.rules.add
                this.$refs['ruleForm'].clearValidate('add')
            },
            imgUpload(a,file,filelist){
                console.log('成功');
                this.rowData.imgpath = this.upImageName.url
                delete this.rules.imgpath
                this.$refs['ruleForm'].clearValidate('imgpath')
                this.vif = false
                this.vif = true
            },
            imgUpload1(a,file,filelist){
                
                console.log(this.upImageName);
                this.imgs.push(this.upImageName.url)
                delete this.rules.imgs

                this.$refs['ruleForm'].clearValidate('imgs')
                this.vifImgs = false
                this.vifImgs = true
                console.log(this.rowData);
            },

            async uploadImgs(file){
                let fileSize = file.file.size//字节
                fileSize = (fileSize/1024/1024) < 1 ? true : false
                if(!fileSize){
                    this.$alertError('选择的文件大于1M')
                    return
                }
                this.upImageName = await uploadOSS(file.file)
                console.log('upload==>',this.upImageName );
               
            },
            getRowData(){
                console.log(this.partyId);
                this.$api.party.getRowInfo({
                    id:this.partyId
                }).then((res)=>{
                    console.log(res);
                    if(res.status == 1){
                        this.rowData = res.obj
                        console.log(this.rowData);
                        this.rowData.add = this.rowData.addcode.split(',')
                        // this.rowData.value1 = []
                        // this.rowData.value1.push(this.rowData.stime)
                        // this.rowData.value1.push(this.rowData.etime)
                        this.value11 = []
                        this.value11.push(this.rowData.stime)
                        this.value11.push(this.rowData.etime)
                        this.imgs = this.rowData.imgs.split(',')
                        this.vif = false
                        this.vifImgs = false
                        this.vif = true
                        this.vifImgs = true
                    }   
                }).catch((error)=>{

                })
            },
            testClick(){
                console.log(1);
            },
            dateChange(vv){
                console.log("datechange==>",vv);
                this.rowData.stime = vv[0].getTime()
                this.rowData.etime = vv[1].getTime() + 1000*60*60*23 + 1000*60*59 + 1000*59
            },
            fileDelete(){

            },
            onSubmit(formName){
                var that = this
            
                console.log(this.rowData);
                this.$refs[formName].validate((valid,str) => { 
                    if(valid){
                        let str
                        let url
                        console.log(that.rowData.id);

                        if(!that.rowData.id){
                            str = '新增'
                            url = that.$api.party.addTableData
                        }else{
                            str = '修改'
                            url = that.$api.party.editTableData
                        }
                        
                        if(typeof(that.rowData.fbtime) != 'number'){
                            that.rowData.fbtime = that.rowData.fbtime.getTime()
                        }
                        that.imgs.forEach(element =>{
                            console.log(element);
                        })
                        let s = ''
                        that.imgs.forEach(element =>{
                            s+=element+','
                        })

                        that.rowData.imgs = s.slice(0,s.length-1)

                        console.log(that.rowData);
                        url(that.rowData).then((res)=>{
                            console.log(res);
                            if(res.status == 1){
                                that.$alertSuccess(res.msg)
                                that.$router.push({
                                    path: "/party",
                                });
                            }
                        }).catch((error)=>{
                            that.$alertError(error.msg)
                            console.log(error);
                        })

                    }
                })
            },
            partyReturn(val){
                this.$api.party.partyReturn({
                    id:this.partyId,
                    status:val
                }).then((res)=>{
                    if(res.status == 1 ){
                        console.log(res);
                        this.$alertSuccess(res.msg)

                        this.$router.push({
                            path: "/party",
                        });
                    }
                }).catch((error)=>{
                    console.log(error);
                    this.$alertError(error.msg)
                })
            },
            deleteImg(index){
                this.imgs.splice(index,1)
                if(this.imgs.length == 0){
                    this.rules.imgs = [{
                        required: true,
                        message: '请选择详情图片',
                        trigger: 'blur'
                    }]
                }
            },
        },
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .test{
        white-space: nowrap;
    }
    .test_text{
        margin-left: 1vw;
    }
    .btns{
        width:100%;
        text-align:center;
        padding-bottom: 20px;
   }
   .el-range-input{
        margin-left: 14px;
   }
   .imgs_button{
        /* float: left; */
        position: absolute;
        z-index: 2;
        margin-left: 80px;
    }
</style>